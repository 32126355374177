import {
    AutocompleteInput,
    BooleanInput,
    Datagrid,
    DateField,
    DateInput,
    EditButton,
    List,
    ReferenceInput,
    ShowButton,
    TextField,
    TextInput,
    useTranslate
} from "react-admin";

import { rowStyles } from "../../utils/rowStyles";
import { ListPagination } from "../../utils/components/ListPagination";

import { YesNoBooleanField } from "../../fields/YesNoBooleanField";
import { ProductListActions } from "./components/ProductListActions";

const classes = {
    booleanField: { position: "relative", bottom: "13px", marginLeft: "10px" },
    selectField: { width: "185px" }
};

export function ProductList(): JSX.Element {
    const t = useTranslate();
    const filters = [
        <TextInput key="filter-text" label={t("resources.product.fields.name")} source="searchTerms" alwaysOn />,
        <TextInput key="filter-text" label={t("resources.product.filters.codeEm")} source="codeEm" />,
        <ReferenceInput key="filter-season" label={t("resources.product.fields.season")} source="seasonUuid" reference="season">
            <AutocompleteInput label={t("resources.product.fields.season")} optionValue="id" optionText="name" />
        </ReferenceInput>,
        <ReferenceInput key="filter-serie" label={t("resources.product.fields.serie")} source="serieUuid" reference="serie">
            <AutocompleteInput label={t("resources.product.fields.serie")} optionValue="id" optionText="name" />
        </ReferenceInput>,

        <ReferenceInput
            key="filter-contentType"
            label={t("resources.product.filters.contentType")}
            source="contentTypeUuid"
            reference="contentType"
        >
            <AutocompleteInput label={t("resources.product.filters.contentType")} optionValue="id" optionText="name" />
        </ReferenceInput>,

        <ReferenceInput
            key="filter-rightHolder"
            label={t("resources.product.filters.rightHolder")}
            source="rightHolderUuid"
            reference="rightHolder"
        >
            <AutocompleteInput label={t("resources.product.filters.rightHolder")} optionValue="id" optionText="name" />
        </ReferenceInput>,

        <ReferenceInput key="filter-format" label={t("resources.product.filters.format")} source="formatUuid" reference="format">
            <AutocompleteInput label={t("resources.product.filters.format")} optionValue="id" optionText="name" />
        </ReferenceInput>,

        <DateInput
            key="publication-from"
            source="rangePublicationDateBegin"
            label={t("resources.product.filters.rangePublicationDateBegin")}
        />,
        <DateInput key="publication-to" source="rangePublicationDateEnd" label={t("resources.product.filters.rangePublicationDateEnd")} />,
        <BooleanInput key="published" source="published" options={{}} sx={classes.booleanField} />,
        <TextInput key="filter-id" label={t("resources.common.fields.id")} source="id" />
    ];

    return (
        <>
            <List pagination={<ListPagination />} perPage={20} filters={filters} actions={<ProductListActions />}>
                <Datagrid rowStyle={rowStyles}>
                    <TextField source="name" />
                    <TextField source="title" />
                    <TextField source="codeEm" />
                    <TextField source="codeEmArte" />
                    <TextField sortBy="rightHolder" source="rightHolder.name" label={t("resources.product.fields.rightHolder")} />
                    <TextField sortBy="contentType" source="contentType.name" label={t("resources.product.fields.contentType")} />
                    <YesNoBooleanField label={t("resources.product.fields.published")} source="published" />
                    <DateField label={t("resources.product.fields.publicationStartedAt")} source="publicationStartedAt" />
                    <DateField label={t("resources.product.fields.publicationEndedAt")} source="publicationEndedAt" />
                    <YesNoBooleanField source="singleContent" />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            </List>
        </>
    );
}
