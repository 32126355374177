import { downloadCSV, TopToolbar, CreateButton, ExportButton, FilterButton } from "react-admin";
import type { Exporter } from "react-admin";
import { i18nProvider } from "../../../i18n";
import jsonExport from "jsonexport/dist";

const EXPORT_FORBIDDEN_KEYS = [
    "shortId",
    "uuid",
    "themeIds",
    "genreIds",
    "rightHolder",
    "rightHolderId",
    "contentType",
    "contentTypeId",
    "seasonId",
    "contentOriginId",
    "formatId",
    "characterId",
    "licenseId"
];

const productExporter: Exporter = async (data, fetchRelatedRecords, __, resource: any) => {
    const themes = await fetchRelatedRecords(data, "themeIds", "theme");
    const genres = await fetchRelatedRecords(data, "genreIds", "genre");
    const seasons = await fetchRelatedRecords(data, "seasonId", "season");
    const contentOrigins = await fetchRelatedRecords(data, "contentOriginId", "contentOrigin");
    const formats = await fetchRelatedRecords(data, "formatId", "format");
    const characters = await fetchRelatedRecords(data, "characterId", "character");
    const licenses = await fetchRelatedRecords(data, "licenseId", "license");

    // Remove useless keys
    for (const line of data) {
        // Add related
        line["themes"] = (line?.themeIds ?? []).map((themeId: string) => themes[themeId].name);
        line["genres"] = (line?.genreIds ?? []).map((genreId: string) => genres[genreId].name);
        line["season"] = seasons[line?.seasonId]?.name ?? "";
        line["contentOrigin"] = contentOrigins[line?.contentOriginId]?.name ?? "";
        line["format"] = formats[line?.formatId]?.name ?? "";
        line["character"] = characters[line?.characterId]?.name ?? "";
        line["license"] = licenses[line?.licenseId]?.name ?? "";
        line["contentType"] = line?.contentType?.name ?? "";
        line["rightHolder"] = line?.rightHolder?.name ?? "";

        for (const key of Object.keys(line)) {
            if (!EXPORT_FORBIDDEN_KEYS.includes(key)) {
                continue;
            }
            delete line[key];
        }
    }

    // Get headers
    const firstLine = data?.[0];
    const headers = Object.keys(firstLine);

    // Translate headers
    const rename = [];

    for (const headerId of headers) {
        const defaultTranslation = i18nProvider.translate(`resources.${resource}.fields.${headerId}`, { _: headerId });
        rename.push(i18nProvider.translate(`resources.${resource}.exportFields.${headerId}`, { _: defaultTranslation }));
    }

    return jsonExport(data, { rowDelimiter: ";", headers, rename }, (_err: any, csv: any) => downloadCSV(csv, resource));
};

export function ProductListActions(): JSX.Element {
    return (
        <TopToolbar sx={{ alignItems: "center" }}>
            <CreateButton />
            <ExportButton maxResults={10000} exporter={productExporter} />
            <FilterButton disableSaveQuery />
        </TopToolbar>
    );
}
