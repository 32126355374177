/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-no-bind */
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    DateInput,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    TabbedForm,
    TextInput,
    useTranslate
} from "react-admin";

import { useInputStyles } from "../../../utils/useInputStyles";

import { ImageListInput } from "../../image/components/Form";
import { CreateToolbar } from "../../../common/CreateToolbar";
import { EditToolbar } from "../../../common/EditToolbar";

export function ProductSimpleFormContent(): JSX.Element {
    const classes = useInputStyles;
    const isCreateMode: boolean = window.location.href.includes("/create");
    const t = useTranslate();
    const formProps: any = {};

    if (isCreateMode) {
        // Create
        formProps["defaultValues"] = { publicationDate: new Date() };
        formProps["toolbar"] = <CreateToolbar />;
    } else {
        // Edit
        formProps["toolbar"] = <EditToolbar />;
    }

    return (
        <>
            <TabbedForm {...formProps}>
                <TabbedForm.Tab label="general">
                    <TextInput sx={classes.inputField} source="name" validate={required()} />
                    <TextInput sx={classes.inputField} source="codeEm" />
                    <TextInput sx={classes.inputField} source="codeEmArte" />
                    <TextInput sx={classes.inputField} source="codeBnf" />
                    <ReferenceInput source="formatId" reference="format">
                        <AutocompleteInput optionValue="id" optionText="name" fullWidth />
                    </ReferenceInput>
                    <ReferenceInput source="contentOriginId" reference="contentOrigin">
                        <AutocompleteInput
                            label={t("resources.product.fields.contentOrigin")}
                            optionValue="id"
                            optionText="name"
                            fullWidth
                        />
                    </ReferenceInput>
                    <ReferenceInput source="licenseId" reference="license">
                        <AutocompleteInput label={t("resources.product.fields.license")} optionValue="id" optionText="name" fullWidth />
                    </ReferenceInput>
                    <ReferenceInput source="rightHolderId" reference="rightHolder">
                        <AutocompleteInput label={t("resources.product.fields.rightHolder")} optionValue="id" optionText="name" fullWidth />
                    </ReferenceInput>
                    <ReferenceInput source="characterId" reference="character">
                        <AutocompleteInput label={t("resources.product.fields.character")} optionValue="id" optionText="name" fullWidth />
                    </ReferenceInput>
                    <ReferenceInput source="contentTypeId" reference="contentType">
                        <AutocompleteInput label={t("resources.product.fields.contentType")} optionValue="id" optionText="name" fullWidth />
                    </ReferenceInput>
                    <ReferenceInput source="seasonId" reference="season">
                        <AutocompleteInput
                            label={t("resources.product.fields.season")}
                            optionValue="id"
                            optionText={(season) => `${season.name} - ${season.serie.name}`}
                            fullWidth
                        />
                    </ReferenceInput>
                    <NumberInput sx={classes.inputField} source="episodePosition" min={1} />
                </TabbedForm.Tab>
                <TabbedForm.Tab label="editorial">
                    <TextInput sx={classes.inputField} source="title" />
                    <TextInput sx={classes.inputField} source="description" fullWidth multiline />
                    <TextInput sx={classes.inputField} source="collection" />
                    <TextInput sx={classes.inputField} source="keywords" />
                    <TextInput sx={classes.inputField} source="serieKeywords" />
                    <ReferenceArrayInput source="genreIds" reference="genre">
                        <AutocompleteArrayInput label={t("resources.product.fields.genres")} optionValue="id" optionText="name" fullWidth />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput source="themeIds" reference="theme">
                        <AutocompleteArrayInput label={t("resources.product.fields.themes")} optionValue="id" optionText="name" fullWidth />
                    </ReferenceArrayInput>
                </TabbedForm.Tab>
                <TabbedForm.Tab label={t("resources.product.tabs.technicalInfo")}>
                    <TextInput sx={classes.inputField} source="country" />
                    <NumberInput sx={classes.inputField} source="year" min={1000} />
                    <NumberInput sx={classes.inputField} source="duration" min={0} />
                    <TextInput sx={classes.inputField} source="version" />
                    <BooleanInput sx={classes.inputField} source="retranscription" />
                    <TextInput sx={classes.inputField} source="credits" fullWidth multiline />
                    <TextInput sx={classes.inputField} source="authors" />
                    <TextInput sx={classes.inputField} source="directors" />
                    <TextInput sx={classes.inputField} source="actors" />
                    <TextInput sx={classes.inputField} source="producers" />
                    <TextInput sx={classes.inputField} source="composers" />
                    <TextInput sx={classes.inputField} source="illustrators" />
                    <TextInput sx={classes.inputField} source="soundContributors" />
                    <TextInput sx={classes.inputField} source="voiceContributors" />
                </TabbedForm.Tab>
                <TabbedForm.Tab label="publication">
                    <BooleanInput sx={classes.inputField} source="published" />
                    <NumberInput sx={classes.inputField} source="minAge" min={0} />
                    <NumberInput sx={classes.inputField} source="maxAge" min={0} />
                    <DateInput source="publicationStartedAt" />
                    <DateInput source="publicationEndedAt" />
                </TabbedForm.Tab>
                <TabbedForm.Tab label="media" path="media">
                    <ImageListInput targetType="product" />
                </TabbedForm.Tab>
            </TabbedForm>
        </>
    );
}
